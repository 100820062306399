import React, { useEffect, useContext } from "react"
import {Link} from 'gatsby'
import Seo from "../components/utilities/Seo"
import RisingSpans from "../components/components/RisingSpans"
import {indexHolder} from './pages.module.scss'
import {motion, useAnimation, usePresence} from 'framer-motion'
import HomepageLogo from '../components/components/HomepageLogo'
import DownArrow from '../images/svgs/home/DownArrow.svg'

import {SectionNavContext} from '../components/utilities/SiteContexts'

import Cookies from 'universal-cookie';


const arrowBounce = {
  pre: {
    opacity:0
  },
  vis: {
    opacity:1,
    y: [10,0,10,0,10],
    transition: {
      repeat: Infinity,
      repeatDelay: 3,
      bounce: 0.7,
      type:'spring',
      mass:2,
      delay:1
    }
  },
  post: {
    opacity:0
  },
}

const IndexPage = () => {
  // const isBrowser = typeof window !== "undefined"

  const headerControls = useAnimation()
  const sloganControls = useAnimation()
  const revolutionControls = useAnimation()
  const logoControls = useAnimation()
  const arrowControls = useAnimation()

  const [isPresent, safeToRemove] = usePresence()

  const cookies = new Cookies();

  const [, setSectionNav] = useContext(SectionNavContext)


  const introAnimation = async () => {
     logoControls.start('vis')
     headerControls.start('vis')
    await sloganControls.start('vis')
    arrowControls.start('vis')
  }


  useEffect(() => {
    introAnimation()
    setSectionNav([
      {
        to: '/',
        title: 'Home',
        active:true
      },
      {
        to: '/about/video',
        title: 'Video'
      },
      { 
        to: '/about/benefits',
        title: 'Benefits'
      },
      {
        to: '/about/expertise',
        title: 'Expertise',
      },
      {
        to: '/about/achievements',
        title: 'Achievements'
      },
      {
        to: '/about/contact',
        title: 'Contact'
      }
    ])
  }, [])


  useEffect(() => {

    const exitAnimation = async () => {


      if(!cookies.get('revolutionCookie')) {
        sloganControls.start('post')
        logoControls.start('post')
        arrowControls.start('post')
        await headerControls.start('post')
      await revolutionControls.start('vis')
      await revolutionControls.start('post')
      cookies.set('revolutionCookie', 'true', { path: '/', maxAge: 86400 })

      } else {

        arrowControls.start('post')
        sloganControls.start('post')
        headerControls.start('post')

        await logoControls.start('post')

      }

      safeToRemove()
    }

    if(!isPresent) {
      exitAnimation()
    }
  }, [isPresent, sloganControls, logoControls, headerControls, revolutionControls, safeToRemove, arrowControls, cookies])
  

  
  return (
    <article className={indexHolder}>
      <Seo title="Carbon Climate Solutions" description="The green revolution is underway" />
      <motion.div className="indexInner" transition={{ staggerChildren:1 }}>
       
        <RisingSpans staggerChildren={0.3} controls={headerControls} exitAnimation={false}><h1>Carbon Climate Solutions.</h1></RisingSpans>

        <RisingSpans staggerChildren={0.08} controls={sloganControls} exitAnimation={false}><h2>We <strong>design,</strong><strong>procure</strong> & <strong>construct</strong> large scale solar energy projects across the world.</h2></RisingSpans>
        
        <RisingSpans staggerChildren={0.2} controls={revolutionControls} exitAnimation={false}><h3>The <span className="green">green</span> revolution is underway...</h3></RisingSpans>

        <HomepageLogo controls={logoControls} />

        <motion.div className="downArrow" variants={arrowBounce} initial="pre" animate={arrowControls}><Link to='/about/video'><DownArrow /></Link></motion.div>

      </motion.div>
    </article>
  )
}

export default IndexPage
