import React from 'react'
import {motion, MotionConfig} from 'framer-motion'
import {logoHolder} from './HomepageLogo.module.scss'

export default function HomepageLogo({controls}) {
  const svgVariants = {
    pre:{
      x: '65vw',
      y: '50vh'
    },
    vis: {
      x: '65vw',
      y: '50vh'
    },
    post: {
      x:'0rem',
      y:'0rem',
      scale:[1, 0.1],
      opacity:[1, 1, 0]
    }
  }
  const earthVariants = {
    pre: {
      opacity:0,
    },
    vis: {
      opacity:1
    }
  }

  const sunHolderVariants = {
    pre: {
      x:'0%',
    },
    vis: {
      x:['120%', '-120%'],
      y:['-40%', '40%'],
      "z-index":[5,15],
      transition: {
        repeat:Infinity,
        repeatType:'reverse',
        duration:3,
        ease: [0.87, 0, 0.13, 1]
        
      }
    }
  }

  const sunVariants = {
    pre: {
      pathLength:0,
      opacity:0,
    },
    vis: {
      pathLength:1,
      opacity:1
    }
  }



  return (
  <MotionConfig transition={{type: 'tween', duration: 2, ease: [0.85, 0, 0.15, 1]}}>
    <motion.div className={logoHolder} initial="pre" variants={svgVariants} animate={controls}>
      <motion.svg  className="earthHolder" width="598" height="598" viewBox="0 0 598 598" fill="none" xmlns="http://www.w3.org/2000/svg">
        <motion.circle variants={earthVariants} initial="pre" animate={controls} cx="299" cy="299" r="299" fill="#44A572"/>
      </motion.svg>
      <motion.div variants={sunHolderVariants} initial="pre" animate={controls} className="sunHolder" >
        <motion.svg  width="407" height="407" viewBox="0 0 407 407" fill="none" xmlns="http://www.w3.org/2000/svg">
          <motion.path variants={sunVariants} initial="pre" animate={controls} d="M405.5 203.5C405.5 315.062 315.062 405.5 203.5 405.5C91.9385 405.5 1.5 315.062 1.5 203.5C1.5 91.9385 91.9385 1.5 203.5 1.5C315.062 1.5 405.5 91.9385 405.5 203.5Z" stroke="black" stroke-width="3"/>
        </motion.svg>
      </motion.div>
    </motion.div>
  </MotionConfig>
  )
}
